import moment from 'moment';
const { space } = require("postcss/lib/list");

jQuery( document ).ready(function($) {

  // music slider
  const swiper = new Swiper('.slider-music', {
    autoHeight: true, //enable auto height
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });

  // videos slider
  const swiper2 = new Swiper('.slider-video', {
    slidesPerView: 1,
    breakpoints: {
      // when window width is >= 320px
      992: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
    }, 
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });

  // events
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Karley Scott Collins/events?app_id=45PRESS_ksc',
    method: 'GET',
    dataType: 'json',
    error: () => {
    },
    success: data => {
        const events = $( '#events' );
        let html = '';
        if ( data.length ) {
            console.log( data );
            for ( let event of data ) {
                const region = event.venue.region !== null && event.venue.region !== '' ? event.venue.region : event.venue.country;
                const location = event.venue.city + ', ' + region;
                html += '<div class="event" data-bit-id="' + event.id + '">';
                html += '<div class="event-date">' + moment( event.datetime ).format( 'MMMM DD, Y' );
                html += '<div class="event-venue-location">';
                html += '<div class="event-location">' + location + '</div>';
                html += '<div class="event-venue">' + event.venue.name + '</div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="event-links">';
                for ( let offer of event.offers ) {
                    if ( offer.type === 'Tickets' ) {
                        html += '<a href="' + offer.url + '" target="_blank" class="btn btn-blue">' + offer.type + '</a>';
                    }
                } 
                html += '</div>';
                html += '</div>';
            }
            events.html( html );
        } else {
            events.html( 'No upcoming events.' );
        }
    }
  });

  // newsletter
  $("#karley-scott-collins-email-signup").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
        type: "POST",
        url: "https://subs.sonymusicfans.com/submit",
        dataType: "json", 
        data: data,
        xhrFields: {
            withCredentials: false
        },
        success: function (data) {
            $('#karley-scott-collins-email-signup').html('<span class="thank-you">Thank You For Signing Up</span>')
        },
        error: function (err) {
            alert("An error has occured!");
        }
    });
  });

  // videos
  $('.video-item').click(function(){
    const videoTitle = $(this).find('.title').html()
    const videoId = $(this).attr('data-video-id')
    const videoFeatured = $('.video-featured')
    $(videoFeatured).find('.title').html(videoTitle)
    $(videoFeatured).find('iframe').attr('src' , `https://www.youtube.com/embed/${videoId}?si=mEQcl683HKiot-Uu`)
  });

});